import React from 'react';

interface Props {
  type: string;
  value: string;
  onChange?: React.ChangeEventHandler;
  className?: string;
  placeholder?: string;
  required?: boolean;
  pattern?: string;
  label?: string;
  error?: string;
  multiple?: boolean;
  labelHelper?: string;
  disabled?: boolean;
  size?: string;
  title?: string;
  icon?: any;
  noWidth?: boolean;
}

const Input: React.FC<Props> = ({
  label,
  type,
  value,
  onChange,
  className,
  placeholder,
  required,
  pattern,
  labelHelper,
  error,
  multiple,
  disabled,
  size,
  title,
  icon,
  noWidth = false,
}) => {
  const widthClassName = noWidth ? '' : size && size === 'full' ? 'w-full' : 'w-80';
  return (
    <label className={`${widthClassName} my-3.5`}>
      {label && (
        <span className="block text-gray-700">
          <span className="font-bold text-base">{label}</span>
          <span className="block md:inline text-xs md:pl-2">{labelHelper}</span>
        </span>
      )}
      {icon && <img src={icon} alt="Icon" className="w-6 absolute m-3" />}
      <input
        type={type}
        value={value}
        onChange={onChange}
        className={
          className ||
          `p-2.5 rounded-lg form-input ${widthClassName} h-12 focus:outline-none focus:primary
          ${error ? ' border-red-400' : ' border-gray-300'}
          ${icon ? 'pl-11' : ''}
          `
        }
        placeholder={placeholder || 'Start Typing...'}
        required={required}
        pattern={pattern}
        multiple={multiple}
        disabled={disabled}
        title={title}
      />
      {error && <div className="text-red-500 text-xs font-bold">{error}</div>}
    </label>
  );
};
export default Input;
