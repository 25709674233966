import React, { useState, useRef, useEffect } from 'react';

// Define the props interface
interface DescriptionProps {
    text: string;
    customClassName?: string;
}

const TrimmedDescription: React.FC<DescriptionProps> = ({ text, customClassName }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isOverflowed, setIsOverflowed] = useState(false);
    const textRef = useRef<HTMLHeadingElement>(null);

    useEffect(() => {
        if (textRef.current) {
            const { clientHeight, scrollHeight } = textRef.current;
            if (scrollHeight > clientHeight) {
                setIsOverflowed(true);
            }
        }
    }, [text]);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className='flex flex-col flex-wrap flex-1'>
            <h3
                className={customClassName || `text-lg font-normal text-gray-500 text-center md:w-80`}
                ref={textRef}
                style={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: isExpanded ? 'unset' : 3,
                    overflow: 'hidden',
                }}
            >
                {text}
            </h3>
            {isOverflowed && (
                <button
                    onClick={toggleExpand}
                    style={{
                        color: 'green', // Set your desired color
                        fontWeight: 600, // Make the font weight bold
                        marginTop: '10px', // Optional: Adds some space between text and button
                    }}
                >
                    {isExpanded ? 'Show Less' : 'Show More'}
                </button>
            )}
        </div>
    );
};

export default TrimmedDescription;
