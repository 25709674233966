import React, { useState, useEffect, Fragment } from 'react';
import Clone from '../../Components/Clone';
import ShowAverageRating from '../../Components/ShowAverageRating';
import FormHeading from '../../Components/Form/FormHeading';
import WhatsAppSVG from '../../../src/public/svg/whatsapp.svg';
import CloneSVG from '../../../src/public/svg/clone.svg';
import GoogleSVG from '../../../src/public/images/google-full.svg';
import Markdown from 'markdown-to-jsx';
import ImageSlider from '../../Components/Slider';
import PrimaryButton from '../../Components/Form/PrimaryButton';
import TrimmedDescription from '../../Components/TrimmedDescription';
import Cookies from 'js-cookie';
import { useNavigate, useParams } from 'react-router-dom';


type Leads = {
  [key: string]: boolean;
};

const Show = () => {

  const base_url = process.env.REACT_APP_API_BASE_URL;
  const [show, setShow] = useState<any>(null)

  const { hotel_id } = useParams();

  const navigate = useNavigate();


  useEffect(() => {
    const fetchCatalogues = async () => {
      try {
        const response = await fetch(`${base_url}/api/hotel/${hotel_id}/show`)

        if (response.ok) {
          const result = await response.json()
          setShow(result)
        } else {
          console.log('error')
        }
      } catch (error) {
        console.error('Error fetching catalogues:', error);
      }
    }

    fetchCatalogues()
  },[hotel_id])


  const [activeSection, setActiveSection] = useState(null);
  const [isScrollIntoViewDisabled, setIsScrollIntoViewDisabled] = useState(false);
  const [clickTargettedSection, setClickTargettedSection] = useState<string>('');
  const [leads, setLeads] = useState<Leads>({});
  const [isGridView, setIsGridView] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);



  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 528);
    };


    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },[]);


  useEffect(() => {
    const existingLeads = JSON.parse(Cookies.get('leads') || '[]');
    setLeads(existingLeads);

    if (existingLeads.length > 0) {
      const leadElement = document.getElementById(existingLeads);
      if (leadElement) {
        leadElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        console.log('Lead element not found');
      }
    } else {
      console.log('No existing leads to scroll');
    }
  }, []);


  useEffect(() => {
    const scrollToRequestedProduct = () => {
      const requestedProduct = Cookies.get('requestedProduct');

      if (requestedProduct) {
        const productElement = document.getElementById(requestedProduct);

        if (productElement) {
          productElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          Cookies.remove('requestedProduct');
        }
      }
    };


    const scrollTimeout = setTimeout(scrollToRequestedProduct, 300);

    return () => clearTimeout(scrollTimeout);
  },[]);


  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const visibleSection: any = entries.find((entry) => entry.isIntersecting)?.target;

      if (visibleSection) {
        setActiveSection(visibleSection.getAttribute('data-section'));

        if (!isScrollIntoViewDisabled) {
          document
            .querySelector('[href="#' + visibleSection.getAttribute('data-section') + '"]')
            ?.scrollIntoView();
        }

        if (visibleSection.getAttribute('data-section') === clickTargettedSection) {
          setIsScrollIntoViewDisabled(false);
        }
      }
    });

    const sections = document.querySelectorAll('[data-section]');

    sections.forEach((section) => observer.observe(section));

    return () => {
      sections.forEach((section) => observer.unobserve(section));
      observer.disconnect();
    };
  }, [isScrollIntoViewDisabled, clickTargettedSection]);


  const handleAskPrice = async (hotelId: string, baseImage: any) => {
    try {
      const userName = Cookies.get('userName');
      const userNumber = Cookies.get('userNumber');

      const product_id = String(baseImage.id);
      const product_source = baseImage.source;
      Cookies.set('requestedProduct', baseImage.id, { expires: 1, sameSite: 'Lax' });
      if (userName && userNumber) {
        sendInquiryToServer(hotelId, product_source, product_id, userName, userNumber);
      } else {
        navigate(`/leads/create/${product_id}/${hotelId}/${product_source}`)
      }
    } catch (error) {
      console.error('error', error)
    }
  };

  const sendInquiryToServer = async (
    hotelId: any,
    product_source: string,
    product_id: string,
    userName: string,
    userNumber: string
  ) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/leads/store`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: userName,
          phone_number: userNumber,
          hotelId,
          product_id,
          product_source,
        }),
      });

      if (response.ok) {
        await response.json();

        const existingLeads = JSON.parse(getCookie('leads') || '{}');

        if (existingLeads[product_id]) {
          alert('You have already requested a price for this product.');
          return;
        }

        existingLeads[product_id] = true;

        setCookie('leads', JSON.stringify(existingLeads), 0);

        setTimeout(() => {
          alert('Inquiry submitted successfully!');
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.error("Submission errors:", error);
      alert(`There was an error in your submission: ${error}`);
    }
  };

  const getCookie = (name: string): string | null => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      const cookieValue = parts.pop()?.split(';').shift();
      return cookieValue ? cookieValue : null;
    }
    return null;
  };

  const setCookie = (name: string, value: string, days: number) => {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value || ''}${expires}; path=/`;
  };

  const handleCategoryClick = (categorySlug: string) => {

    const section = document.getElementById(categorySlug);

    if (section) {
      section.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }

    setClickTargettedSection(categorySlug);
  };


  const groupByParentId = (images: any[]) => {
    const grouped: { [key: number]: any[] } = {};

    if (!Array.isArray(images)) {
      return grouped;
    }

    images.forEach((image: any) => {
      const parentId = parseInt(image.parent_id, 10);

      if (parentId === 0) {
        if (!grouped[image.id]) {
          grouped[image.id] = [image];
        }
      } else {
        if (!grouped[parentId]) {
          grouped[parentId] = [];
        }
        grouped[parentId].push(image);
      }
    });

    return grouped;
  };



  const createCards = (gallery: { [key: string]: any[] }, selectedCategory: string) => {

    const images = Object.values(gallery).flat();

    if (!Array.isArray(images)) {
      return [];
    }

    const groupedImages = groupByParentId(images);

    const filteredImages = images.filter((image) => {
      return image.url && (
        selectedCategory === 'all'
          ? true
          : image.category?.toLowerCase()?.replace(/ /g, '-') === selectedCategory
      );
    });


    const cards = Object.keys(groupByParentId(filteredImages)).map((key) => {
      const parentId = parseInt(key);

      const baseImages = filteredImages.filter((img) => img.id === parentId && img.url);
      const baseImage = baseImages.length > 0 ? baseImages[0] : null;

      const childImages = (groupedImages[parentId] || []).filter((img) => img.id !== parentId && img.url);

      if (baseImage) {
        return {
          baseImage,
          childImages,
        };
      }

      return null;
    }).filter(card => card !== null);


    filteredImages
      .filter(
        (image: any) =>
          image.parent_id === 0 && image.url &&
          !cards.some((card) => card?.baseImage?.id === image.id)
      )
      .forEach((image: any) => {
        cards.push({
          baseImage: image,
          childImages: [],
        });
      });

    return cards;
  };


  const handleViewChange = (viewType: any) => {
    setIsGridView(viewType === 'grid');
  };


  const galleryCategoriesArr = Array.isArray(show?.galleryCategories)
    ? show.galleryCategories
    : [];

  const galleryArr = show?.gallery

  const renderSortedCategories = () => {
    const categorized = galleryCategoriesArr.filter((category: any) => category && category !== 'Uncategorized');
    const uncategorized = galleryCategoriesArr.filter((category: any) => !category);

    const sortedCategories = [...categorized, ...uncategorized];

    return sortedCategories.map((category: any, key: any) => {
      const categorySlug = category?.toLowerCase()?.replace(/ /g, '-');
      const cards = createCards(flattenedGallery, categorySlug);

      if (cards.length === 0) return null;

      return (
        <Fragment key={key}>
          <div className="px-5 pt-12 pb-5 font-bold text-left w-full text-lg"
            id={categorySlug}>
            {category && category.trim() ?
              category.charAt(0).toUpperCase() + category.slice(1)
              : 'Uncategorized'}
            <span className="font-normal text-gray-400">{' (' + cards.length + ')'}</span>
          </div>
          <div className={`${isGridView ? 'grid grid-cols-2 gap-0 mt-0' : 'flex flex-wrap gap-10 mt-5'} mx-auto justify-center`}>
            {cards.map((card: any, index: number) => {
              const { baseImage, childImages } = card;
              const allImagesForSlider = [baseImage, ...childImages];
              const hasInquired = leads[baseImage.id];
              const fileType = baseImage?.type || baseImage?.url?.split('.').pop();
              const isVideoType = ['mp4', 'mov', 'mpg', 'mpeg', 'm4v', 'avi', 'mkv', 'x-m4v'].some(ext => fileType?.includes(ext));

              const imageUrl = baseImage?.url ? `${process.env.REACT_APP_API_BASE_URL}${baseImage?.url}` : null;

              return (
                <div
                  className={`${isGridView ? 'w-44 p-2 ml-1 my-2' : 'w-96 flex flex-col p-5 m-3'} bg-white border rounded-lg shadow-md`}
                  id={baseImage?.id}
                  data-section={categorySlug && categorySlug.trim() ? categorySlug : 'uncategorized'}
                  key={index}
                >
                  {baseImage ? (
                    <div>
                      {isVideoType ? (
                        <video
                          controls
                          autoPlay
                          muted
                          className={`${isGridView ? 'h-40 w-full' : 'xl:h-80 xl:w-80 h-fit w-full'} object-cover mb-1 rounded-lg mx-auto`}
                        >
                          <source src={imageUrl || ''} />
                          Your browser does not support the video tag.
                        </video>
                      ) : allImagesForSlider.length > 1 ? (
                        <ImageSlider
                          imageClassName={`${isGridView ? 'h-40 w-full' : 'xl:h-80 xl:w-80 h-fit w-full mx-auto'} object-cover mb-1 rounded-lg`}
                          images={allImagesForSlider.map((file: any) => ({
                            url: file.url ? `${process.env.REACT_APP_API_BASE_URL}${file.url}` : '',
                          }))}
                          height={isGridView ? 160 : 320}
                        />
                      ) : (
                        <img
                          className={`${isGridView ? 'h-40 w-full' : 'xl:h-80 xl:w-80 h-fit w-full'} object-cover mb-1 rounded-lg mx-auto`}
                          src={imageUrl || ''}
                          alt="card"
                        />
                      )}
                      {baseImage?.title && (
                        <h2 className={`${isGridView ? 'text-lg text-center font-bold overflow-hidden' : 'text-xl text-center font-bold'}`}>
                          {baseImage.title}
                        </h2>
                      )}
                      <TrimmedDescription
                        customClassName="whitespace-pre-wrap ml-1"
                        text={baseImage?.description}
                      />
                      {baseImage?.ask_price && hasInquired ? (
                        <p className="text-lg font-bold text-center">
                          {(!baseImage?.price || baseImage.price === '0') ? (
                            `The Price will be Available soon. If urgent, please contact ${show?.enquiryNumber}`
                          ) : (
                            `Price: ₹${baseImage.price}`
                          )}
                        </p>
                      ) : baseImage?.ask_price ? (
                        <div className="flex justify-center items-center">
                          <PrimaryButton
                            size="small"
                            onClick={() => {
                              handleAskPrice(show?.hotel.id, baseImage);
                            }}
                            value={'Ask Price'}
                          />
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <p>No base image available</p>
                  )}
                </div>
              );
            })}
          </div>
        </Fragment>
      );
    });
  };


  const flattenedGallery = galleryArr

  return (

    <>
      <div className="fixed bottom-0 right-0 md:bottom-10 md:right-2 lg:right-10 shadow-lg w-1/2 md:w-auto">
        <a
          className="text-white bg-whatsapp-300 hover:bg-whatsapp-500 flex items-center justify-center p-2.5 px-10 md:rounded-full font-medium"
          target="_blank"
          href={`https://wa.me/${show?.enquiryNumber}?text=Hello, I want to enquire.`}
          rel="noreferrer"
        >
          <img src={WhatsAppSVG} alt="" className="h-5 mr-3" />
          Enquire Now
        </a>
      </div>

      <div className="bg-gray-100 px-0 lg:px-16 pt-6 min-h-screen">
        <div className="pt-5 relative px-6">
          <div>
            <div className="flex justify-between flex-wrap items-start">
              <div>
                <h1 className="text-3xl font-bold">{show?.hotel?.catalogue_name}</h1>
              </div>
              <Clone icon={CloneSVG} href={`/hotel/${show?.hotel_id}/show`} value="clone" />

            </div>
            <div className="flex items-center justify-between flex-wrap">
              <ShowAverageRating averageRating={show?.hotel?.ratings} />
              <div className=" text-sm  my-5  text-gray-500">{show?.hotel?.reviews} reviews</div>
            </div>

            <span className="whitespace-pre-wrap mb-5">
              {show?.hotel?.description !== '' ? (
                <TrimmedDescription
                  customClassName="whitespace-pre-wrap text-lg font-normal text-gray-500"
                  text={show?.hotel?.description}
                />
              ) : (
                ''
              )}
              {isMobile && (
                <div className="flex flex-row justify-center my-4">
                  <button
                    onClick={() => handleViewChange('list')}
                    className={`m-1 px-5 py-2 rounded-3xl font-semibold transition-all duration-300 ease-in-out ${!isGridView ? 'bg-blue-500 text-white text-sm' : 'bg-gray-200 text-gray-700'
                      }`}
                  >
                    List View
                  </button>
                  <button
                    onClick={() => handleViewChange('grid')}
                    className={`m-1 px-5 py-2 rounded-3xl font-semibold transition-all duration-300 ease-in-out ${isGridView ? 'bg-blue-500 text-white text-sm' : 'bg-gray-200 text-gray-700'
                      }`}
                  >
                    Grid View
                  </button>
                </div>
              )}
            </span>
          </div>
        </div>

        <div className="sticky top-0 z-50 py-2 flex overflow-auto bg-gray-100 shadow-lg px-2 md:px-5 -mx-6 lg:mx-0 lg:px-0">
          {galleryCategoriesArr && galleryCategoriesArr.length > 0 &&
            galleryCategoriesArr.map((category: any, key: any) => {
              const categoryName = category === null ? 'Uncategorized' : category;
              const capitalizedCategoryName = categoryName.charAt(0).toUpperCase() + categoryName.slice(1);
              let categorySlug = capitalizedCategoryName.toLowerCase().replace(/ /g, '-');
              const cards = createCards(flattenedGallery, categorySlug);

              if (cards.length > 0) {
                return (
                  <button
                    className={`${activeSection === categorySlug
                      ? 'text-white bg-primary-400 border-b-4 border-l-4 border-r-4 border-white'
                      : 'border-b border-l bg-primary-400 hover:bg-primary-700 border-r text-gray-400'
                      } py-2 px-5 flex-1 text-center whitespace-nowrap font-bold text-lg`}
                    onClick={() => handleCategoryClick(categorySlug)}
                    key={key}
                  >
                    {capitalizedCategoryName}
                  </button>
                );
              }
              return null;
            })}
        </div>

        {renderSortedCategories()}
        {show?.hotel?.show_google_reviews === 1 && (
          <div className="flex flex-wrap mt-5 items-center px-6" >
            <FormHeading value="Reviews from" />
            <img src={GoogleSVG} alt="" className="h-10 ml-2" />

            <div className="md:w-full flex flex-wrap justify-center mx-auto">
              <div className="flex flex-col">
                {show?.hotel?.google_reviews?.map((review: any, key: any) => (
                  <div className=" py-3" key={key}>
                    <div className="mt-2 flex items-center justify-between">
                      <div className="flex items-center">
                        <img
                          src={review?.profile_photo_url}
                          alt={review?.author_name}
                          className="h-10 mr-2"
                        />
                        <span className="font-bold">{review?.author_name}</span>
                      </div>
                      <ShowAverageRating averageRating={review?.rating} />
                    </div>
                    <span className="text-xs pb-5">
                      <Markdown className="whitespace-pre-wrap">{review?.text}</Markdown>
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {show?.hotel?.show_location === 1 && show?.hotel?.business_name !== '' && (
          <div className="flex flex-wrap flex-col mt-5 items-center w-full px-6">
            <FormHeading value="Location" />
            <div className="md:w-full flex flex-wrap justify-center mx-auto w-full">
              <div className="flex flex-col w-full">
                <div className="mx-auto w-full my-3.5">
                  <iframe
                    title='Mapping'
                    className="w-full h-96"
                    id="gmap_canvas"
                    src={
                      `${show?.hotel?.created_by?.email}` === 'imagineartandprint@gmail.com'
                        ? 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.5562255391225!2d72.8291744!3d21.179588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f01de6503b5%3A0x2b0dfae6da2d1351!2sImagine%20Art%20%26%20Print!5e0!3m2!1sen!2sin!4v1692384619462!5m2!1sen!2sin'
                        : `https://maps.google.com/maps?q=${show?.hotel?.business_name}&t=&z=13&ie=UTF8&iwloc=&output=embed`
                    }
                    frameBorder="0"
                    scrolling="no"
                    marginHeight={0}
                    marginWidth={0}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        )}


      </div>
      {/* <ShowVisitCount
        visitCount={show?.hotel?.visit_count}
        hotelName={show?.hotel?.catalogue_name}
        onClose={handleCloseVisitCount}
      /> */}
    </>
  );

};

export default Show;


