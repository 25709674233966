import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface Image {
  url: string;
}

interface Props {
  images?: Image[];
  height: number;
  imageClassName?: string;
}

const ImageSlider: React.FC<Props> = ({ images, height,imageClassName }) => {
  const settings = {
    accessibility: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const sliderStyle = {
    height: `${height}px`, // Set the height dynamically
  };

  const imageStyle: React.CSSProperties = {
    height: `${height}px`,
    objectFit: 'cover',
  };

  return (
    <div className="slider-container" style={sliderStyle}>
      <Slider {...settings}>
        {images &&
          images.map((image, index) => (
            <div key={index}>
              <img
                src={image.url}
                alt={`Slide ${index + 1}`}
                className={imageClassName || `w-full`}
                style={imageStyle}
              />
            </div>
          ))}
      </Slider>
    </div>
  );
};

export default ImageSlider;
