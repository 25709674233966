import React from 'react';
import { InertiaLink } from '@inertiajs/inertia-react';

interface Props {
  type?: 'button' | 'submit' | 'reset';
  value: string;
  disabled?: boolean;
  href?: string;
  size?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const SecondaryButton: React.FC<Props> = ({ type, value, disabled, href, size, onClick }) => {
  const widthClassName = size && size === 'small' ? 'w-40' : 'w-64 md:w-80';
  return href ? (
    <InertiaLink
      href={href}
      className={`${widthClassName} inline-flex justify-center mt-3.5 p-2.5 text-white rounded-md font-medium bg-gray-400 hover:bg-gray-700`}
    >
      {value}
    </InertiaLink>
  ) : (
    <button
      type={type}
      className={`${widthClassName} mt-3.5 p-2.5 text-white rounded-md font-medium
          ${disabled ? ' bg-gray-400 cursor-not-allowed' : ' bg-gray-400 hover:bg-gray-700'}`}
      disabled={disabled}
      onClick={onClick}
    >
      {value}
    </button>
  );
};

export default SecondaryButton;
