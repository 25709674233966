import React from 'react';
import StarFilled from '../../src/public/svg/starfilled.svg';

interface Props {
  averageRating?: any;
}

const ShowAverageRating: React.FC<Props> = ({ averageRating }) => (
  <div>
    {averageRating > 0 && (
      <div className="flex items-center">
        <img src={StarFilled} alt="Star Filled" className="w-8" />
        <span className="text-base md:text-xl pl-2">{averageRating}</span>
      </div>
    )}
  </div>
);

export default ShowAverageRating;
