import React from 'react';

interface Props {
  value: string;
  className?: string;
}

const FormHeading: React.FC<Props> = ({ value, className }) => (
  <h1 className={`${className} mb-2 text-3xl text-gray-700 font-bold text-center pl-0`}>{value}</h1>
);

export default FormHeading;
