import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import FormHeading from '../../Components/Form/FormHeading';
import Input from '../../Components/Form/Input';
import PrimaryButton from '../../Components/Form/PrimaryButton';
import DefaultCardLayout from '../../Layouts/DefaultCardLayout';
import SecondaryButton from '../../Components/Form/SecondaryButton';
import { useForm } from '@inertiajs/inertia-react';

const Create = () => {
  const { product_id = '', hotel_id = '', product_source = '' } = useParams<{
    product_id: string;
    hotel_id: string;
    product_source: string;
  }>();
  
  const base_url = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  const { data, setData, errors, clearErrors, processing } = useForm({
    name: '',
    phone_number: '',
  });

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    Cookies.set('requestedProduct', product_id, { expires: 1, sameSite: 'Lax' });

    try {
      const response = await fetch(`${base_url}/api/leads/store`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: data.name,
          phone_number: data.phone_number,
          product_id,
          hotel_id,
          product_source,
        }),
      });

      if (response.ok) {
        const existingLeads = JSON.parse(getCookie('leads') || '{}');

        if (existingLeads[product_id]) {
          alert('You have already requested a price for this product.');
          return;
        }

        existingLeads[product_id] = true;
        setCookie('leads', JSON.stringify(existingLeads), 0);
        Cookies.set('userName', data.name, { expires: 1, sameSite: 'Lax' });
        Cookies.set('userNumber', data.phone_number, { expires: 1, sameSite: 'Lax' });
        setShowSuccessMessage(true);

        setTimeout(() => {
          alert('Thanks !! Your request for asking the best price has been submitted successfully.');
          navigate(`/hotel/${hotel_id}/show`);
        }, 2000);
      } else {
        alert('Error submitting request.');
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
      alert('An error occurred while submitting the form. Please try again.');
    }
  };

  const getCookie = (name: string): string | null => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      const cookieValue = parts.pop()?.split(';').shift();
      return cookieValue || null;
    }
    return null;
  };

  const setCookie = (name: string, value: string, days: number) => {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value || ''}${expires}; path=/`;
  };

  const handleChange = (fieldname: 'name' | 'phone_number', e: React.ChangeEvent<HTMLInputElement>) => {
    clearErrors(fieldname);
    setData(fieldname, e.target.value);
  };

  return (
    <div className="px-6 lg:px-16 py-6">
      <DefaultCardLayout size="small">
        <FormHeading value="Get The Best Price" />

        {showSuccessMessage && (
          <div className="text-green-500 text-center mb-4">
            Thanks !! Your request for asking the best price has been submitted successfully.
            We will get back to you soon within minutes.
          </div>
        )}

        {!showSuccessMessage && (
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="w-80 md:w-full flex flex-wrap justify-center mx-auto">
              <Input
                type="text"
                label="Name"
                placeholder="Enter your name"
                error={errors.name}
                value={data.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('name', e)}
              />
              <Input
                type="number"
                label="Phone Number"
                placeholder="Enter your phone number"
                error={errors.phone_number}
                value={data.phone_number}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('phone_number', e)}
              />
              <div className="flex gap-2">
                <SecondaryButton value="Cancel" disabled={processing} size="small" />
                <PrimaryButton type="submit" value="Enquire Now" disabled={processing} size="small" />
              </div>
            </div>
          </form>
        )}
      </DefaultCardLayout>
    </div>
  );
};

export default Create;
