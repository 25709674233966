import React from 'react';

interface Props {
  children: React.ReactNode;
  size?: string;
}

const DefaultCardLayout: React.FC<Props> = ({ children, size }) => {
  let widthClassName = size && size === 'small' ? 'md:w-164' : 'lg:w-192 md:w-180';
  widthClassName = size && size !== 'small' ? size : widthClassName;
  return (
    <div
      className={`${widthClassName} bg-transparent md:bg-white mx-auto md:p-8 rounded md:shadow-md items-center justify-center`}
    >
      {children}
    </div>
  );
};

export default DefaultCardLayout;
