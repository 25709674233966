import React from 'react';
import HotelPlaceholder from '../../src/public/images/hotel_placeholder.png';
import ShowAverageRating from './ShowAverageRating';
import { Link } from 'react-router-dom';

interface SmallHodelCrops {
  id: any;
  image?: string;
  title?: string;
  slug: string;
  businessName?: string;
  categories?: any;
  ratings?: any;
  reviews?: any;
}
const SmallHotelCard: React.FC<SmallHodelCrops> = ({
  id,
  title,
  image,
  slug,
  businessName,
  categories,
  ratings,
  reviews,
}) => (
  <Link
    className="w-46 h-38 mt-5 flex border border-gray justify-items-center shadow-md transform transition duration-500 hover:scale-105 bg-white mx-2 rounded-lg"
    to={`/hotel/${slug}/show`}
  >
    <div className="mx-auto">
      <div className="relative">
        <img
          className="md:h-80 md:w-80 h-fit w-full mb-3 rounded-t-lg mx-auto"
          src={image ? `${process.env.REACT_APP_API_BASE_URL}${image}` : HotelPlaceholder}
          alt="card"
        />
        <div className="absolute inset-0 ">
          <div className="flex flex-wrap">
            {categories.map((category: any) => (
              <span className="bg-pink-500 text-white rounded-lg px-3 m-2 py-1 md:text-sm text-xs">
                {category.title}
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className="relative px-5">
        <div className="w-2/3">
          <span className="text-black-700 text-lg w-full md:w-auto md:text-left font-semibold mb-5">
            {title}
          </span>
          <div className=" text-sm   text-gray-500">
            by <b>{businessName}</b>
          </div>
          <div className=" text-sm  my-5  text-gray-500">{reviews} reviews</div>
        </div>
        <div className="absolute top-0 right-0 mt-3 mr-3">
          <ShowAverageRating averageRating={ratings} />
        </div>
      </div>
    </div>
  </Link>
);

export default SmallHotelCard;
