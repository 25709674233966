import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import HotelShow from './Pages/Hotel/Show';
import LeadCreate from './Pages/Leads/Create';
import UserShow from './Pages/User/Show';


const App = () => {

  const userId = `${process.env.REACT_APP_USER_ID}` || ''
  localStorage.setItem('user_id', userId)

  return (
    <Router>
      <Routes>
        
        <Route path="/hotel/:hotel_id/show" element={<HotelShow />} />
        <Route path="/leads/create/:product_id/:hotel_id/:product_source" element={<LeadCreate />} />
        <Route path="/" element={<UserShow />} />
      </Routes>
    </Router>
  );
}

export default App;
