import React from 'react';
import { InertiaLink } from '@inertiajs/inertia-react';

interface Props {
  type?: 'button' | 'submit' | 'reset';
  value: string;
  disabled?: boolean;
  href?: string;
  size?: string;
  whiteColor?: boolean;
  notMarginTop?: boolean;
  icon?: any;
  onClick?: any;
}

const PrimaryButton: React.FC<Props> = ({
  type,
  value,
  disabled,
  href,
  size,
  notMarginTop,
  whiteColor = false,
  icon,
  onClick,
}) => {
  const widthClassName = size && size === 'small' ? 'w-40' : size === 'full' ? 'w-full' : 'w-80';
  const marginTopClassName = notMarginTop ? '' : 'mt-3.5';
  const colorClassName = whiteColor
    ? 'text-primary-400 border-2 border-primary-400 bg-white hover:bg-primary-700 hover:text-white'
    : 'text-white bg-primary-400 hover:bg-primary-700';
  return href ? (
    <InertiaLink
      href={href}
      className={`${widthClassName} ${marginTopClassName} ${colorClassName} inline-flex justify-center p-2.5 rounded-lg font-medium flex items-center justify-center`}
    >
      {icon && <img src={icon} alt="Icon" className="w-6 mr-2" />}
      {value}
    </InertiaLink>
  ) : (
    <button
      type={type}
      className={`${widthClassName} ${marginTopClassName} p-2.5 rounded-lg font-medium 
          ${
            disabled ? ' bg-gray-400 cursor-not-allowed' : colorClassName
          } flex items-center justify-center`}
      disabled={disabled}
      onClick={onClick}
    >
      {icon && <img src={icon} alt="Icon" className="w-6 mr-2" />}
      {value}
    </button>
  );
};

export default PrimaryButton;
