import React, { useEffect, useState } from 'react';
import HotelListCards from '../../Components/HotelListCards';
import HotelOwnerIcon from '../../Components/HotelOwnerIcon';
import UserCoverPlaceholder from '../../../src/public/png/user_placeholder.png';
import SocialSharing from '../../Components/SocialSharing';
import CopyToClipboard from '../../Components/CopyToClipboard';



const Show = () => {

  const base_url = process.env.REACT_APP_API_BASE_URL;
  const user_id = process.env.REACT_APP_USER_ID;
  
  const [show, setShow] = useState<any>([])

  useEffect(() => {
    const fetchCatalogues = async () => {
      try {
        const response = await fetch(`${base_url}/api/users/${user_id}/show`);
        if (response.ok) {
          const result = await response.json();
          setShow(result);
        } else {
          const errorText = await response.text();
          console.error('API Error:', response.status, errorText);
        }
      } catch (error) {
        console.error('Fetch Error:', error);
      }
    };


    fetchCatalogues()
  }, [user_id])

  const imageUrl = show?.coverImageUrl
    ? `${base_url}${show?.coverImageUrl}`
    : UserCoverPlaceholder;


  return (
    <div className="bg-gray-100 px-6 lg:px-16 pt-6 min-h-screen">
      <div className="pt-5 relative">
        <img
          src={imageUrl}
          alt="Cover"
          className="w-full h-40 object-cover rounded-t-lg"
        />
        <div className="absolute top-10 right-5 bg-white p-3 rounded-full flex shadow-lg">
          <SocialSharing
            value={show?.user?.email?.split('@')[0]?.toLowerCase()} />
        </div>
        <div className="">
          <div className="flex justify-between flex-wrap items-center pl-8">
            <div>
              <div className="-mt-10">
                <HotelOwnerIcon userName={show?.user?.name} size="large" />
              </div>
              <div className="flex">
                <h1 className="text-lg font-bold">{show.user?.name}</h1>
                <CopyToClipboard
                  value={show?.user?.email?.split('@')[0]?.toLowerCase()}
                />
              </div>
              <div className=" text-sm text-gray-500">
                @<b>{show?.user?.email?.split('@')[0]?.toLowerCase()}</b>
              </div>
            </div>
            <div>
              <a
                className="my-5 text-white bg-primary-400 hover:bg-primary-700 flex items-center justify-center p-2.5 px-10 rounded-lg font-medium"
                target="_blank"
                href={`https://wa.me/${show?.user?.phone_number}?text=Hello, I want to enquire about your hotel.`}
                rel="noreferrer"
              >
                Enquire Now
              </a>
            </div>

            <div className="flex lg:w-1/2 w-full flex-wrap ">
              <div className="flex flex-col text-center border-l border-gray-400 lg:w-1/3 w-36">
                <span className="text-gray-600 uppercase font-bold">Catalogues</span>
                <span className="font-bold">{show?.totalHotels}</span>
              </div>
              <div className="flex flex-col text-center border-l border-gray-400 lg:w-1/3 w-36">
                <span className="text-gray-600 uppercase font-bold">Rating</span>
                <span className="font-bold">{show?.rating}</span>
              </div>
              <div className="flex flex-col text-center border-l border-r border-gray-400 lg:w-1/3 w-36">
                <span className="text-gray-600 uppercase font-bold">Reviews</span>
                <span className="font-bold">{show?.totalReviews}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <div className="my-5">
          <HotelListCards
            userId={show?.user?.id}
            categories={window.location.search.split('categories=')[1]}
            hotels={window.location.search.split('hotels=')[1]}
          />
        </div>
      </div>
    </div>
  );
};

export default Show;
