import React from 'react';
import ShareSVG from '../../src/public/svg/share-icon.svg';
import favicon from '../../src/public/png/favicon.png';
import ShareOnSocial from 'react-share-on-social';

interface Props {
  value: string;
  inline?: boolean;
}


const SocialSharing: React.FC<Props> = ({ value, inline }) => (
  <ShareOnSocial
    link={value}
    noReferer
    shareTo={['facebook', 'whatsapp', 'twitter', 'linkedin', 'telegram']}
    linkFavicon={favicon}
  >
    <button>
      <img src={ShareSVG} alt="Icon" className={`w-6 ${inline ? '' : ''}`} />
    </button>
  </ShareOnSocial>
);

export default SocialSharing;
