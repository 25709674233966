import React, { useState } from 'react';
import CopySVG from '../../src/public/svg/copy.svg';

interface Props {
  value: string;
  child?: any;
  className?: any;
}

const CopyToClipboard: React.FC<Props> = ({ value, child, className }) => {
  const [copied, setCopied] = useState(false);
  const unsecuredCopyToClipboard = (text = '') => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
  };

  /**
   * Copies the text passed as param to the system clipboard
   * Check if using HTTPS and navigator.clipboard is available
   * Then uses standard clipboard API, otherwise uses fallback
   */
  const copyToClipboard = (content = '') => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(content);
    } else {
      unsecuredCopyToClipboard(content);
    }
  };

  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        setTimeout(() => {
          setCopied(false);
        }, 3000);
        setCopied(true);
        copyToClipboard(value);
        return false;
      }}
      className={className}
    >
      {copied ? (
        <span className="text-xs">copied</span>
      ) : child ? (
        child
      ) : (
        <img src={CopySVG} alt="Icon" className={`w-6 `} />
      )}
    </button>
  );
};

export default CopyToClipboard;
