import React from 'react';

interface HotelOwnerIconProps {
  userName: string;
  bgColor?: any;
  size?: any;
  textColor?: any;
  fontSemibold?: boolean;
}

const HotelOwnerIcon: React.FC<HotelOwnerIconProps> = ({
  userName,
  bgColor,
  size,
  textColor,
  fontSemibold,
}) => {
  const iconSize = size === 'large' ? 'w-20 h-20 text-3xl' : 'w-10 h-10 text-lg';
  const backgroundColor = bgColor ? `bg-${bgColor}` : 'bg-rose-900';
  const letterColor = textColor ? `text-${textColor}` : 'text-white';
  const fontWeightClass = fontSemibold ? 'font-semibold' : 'font-bold';

  return (
    <span
      className={`${iconSize} ${backgroundColor} ${letterColor}  ${fontWeightClass}  flex items-center justify-center rounded-full text-center`}
    >
      {userName
        ?.split(' ')
        .map((eachWord: any) => eachWord.charAt(0).toUpperCase())
        .slice(0, 2)}
    </span>
  );
};

export default HotelOwnerIcon;
