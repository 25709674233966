import React from 'react';
import { InertiaLink } from '@inertiajs/inertia-react';

interface Props {
  value: string;
  href: string;
  className?: string;
  label?: string;
  textSize?: string;
  size?: string;
  active?: any;
  icon?: any;
  align?: string;
  whiteSpaceWrapped?: boolean;
}

const Clone: React.FC<Props> = ({
  label,
  value,
  className,
  href,
  textSize,
  size,
  active,
  icon,
  align,
  whiteSpaceWrapped,
}) => {
  const textSizeClassName = textSize && textSize === 'large' ? 'text-lg ' : 'text-sm ';
  const alignmentClassName =
    align && align === 'left' ? 'md:justify-start justify-center' : ' justify-center';
  const activeClassName = active ? 'text-primary-300' : 'text-gray-700 ';
  const activeBgClassName = active ? 'bg-primary-50 py-2 rounded-lg' : '';
  return (
    <label className={` text-center`}>
      <span className={` flex justify-end items-center ${activeBgClassName} text-center`}>
        {label && <span className={`${textSizeClassName} text-gray-700 mr-2`}>{label}</span>}
        <InertiaLink
          href={href}
          className={
            className ||
            `${textSizeClassName} flex ${alignmentClassName} items-center font-bold ${
              whiteSpaceWrapped ? '' : ' whitespace-nowrap '
            } ${activeClassName} text-center hover:text-primary-300`
          }
        >
          <div className="items-end">
            {<img src={icon} alt="Icon" className="w-4" />}
            <span className="text-xs">{`${value !== '' ? value : ''}`}</span>
          </div>
        </InertiaLink>
      </span>
    </label>
  );
};

export default Clone;
