import React, { useState } from 'react';
import ShowMore from '../../src/public/svg/showmore.svg';
import { useInfiniteQuery } from 'react-query';
import SmallHotelCard from './SmallHotelCard';

interface HotelListCardsProps {
  category?: any;
  userId?: any;
  categories?: any;
  hotels?: any;
}

const HotelListCards: React.FC<HotelListCardsProps> = ({
  category,
  userId,
  categories,
  hotels,
}) => {
  const [list, setList] = useState<any>([])
  const fetchHotels = async ({
    pageParam = 1,
    categoryParam = category,
    userParam = userId,
    categoriesParam = categories,
    hotelsParam = hotels,
  }: any) => {
    const url = new URL('/api/hotels/listing', process.env.REACT_APP_API_BASE_URL);

    url.searchParams.append('page', pageParam.toString());
    if (categoryParam) url.searchParams.append('category', categoryParam);
    if (userParam) url.searchParams.append('userId', userParam);
    if (categoriesParam) url.searchParams.append('categories', categoriesParam);
    if (hotelsParam) url.searchParams.append('hotels', hotelsParam);

    const response = await fetch(url.toString());

    if (!response.ok) {
      throw new Error(`Failed to fetch hotels: ${response.statusText}`);
    }
    const result = await response.json();
    setList(result)
    return result;
  };

  
  const { data, isLoading, error, fetchNextPage, hasNextPage, isFetching }: any = useInfiniteQuery(
    ['getHotelList', category, userId],
    fetchHotels,
    {
      getNextPageParam: (lastPage: any) => {
        if (!lastPage || typeof lastPage.current_page === 'undefined' || typeof lastPage.last_page === 'undefined') {
          return undefined;
        }

        if (lastPage.current_page === lastPage.last_page) {
          return undefined;
        }

        return lastPage.current_page + 1;
      },
    }
  );




  if (isLoading) return <div className="text-center">Loading...</div>;

  if (error) return <div className="text-center">{error.message}</div>;

  return (
    <>
      <div className="flex flex-wrap justify-center">
        {data?.pages?.map((page: any) => {
          return page?.data.length > 0 ? (
            page?.data?.map((hotel: any) => (
              <SmallHotelCard
                title={hotel?.catalogue_name}
                id={hotel.id}
                image={
                  hotel?.files?.length > 0
                    ? hotel?.files[0].url
                    : hotel?.googlePhotos?.length > 0
                      ? hotel?.googlePhotos[0].url
                      : ''
                }
                key={hotel.id}
                slug={hotel.id}
                businessName={hotel.created_by}
                categories={hotel.categories}
                ratings={hotel.ratings}
                reviews={hotel.reviews}
              />
            ))
          ) : (
            <div className="text-center w-full">{'No Catalogue Found'}</div>
          );
        })}
      </div>
      <div
        className="mt-5 text-xl text-center pb-5 mb-8 flex justify-center cursor-pointer"
        onClick={() => {
          fetchNextPage();
        }}
        aria-hidden
      >
        {hasNextPage &&
          (isFetching ? (
            'Loading more catalogues for you'
          ) : (
            <>
              Show more Catalogues
              <img src={ShowMore} className="ml-4" alt="show more" />
            </>
          ))}
      </div>
    </>
  );
};

export default HotelListCards;
